
.smoke-h1{
    /* transform: translateY(-50%); */
    text-align: center;
    color: #4fb5a7;
    font-size: 4em;
    font-family:sans-serif;
    text-transform: uppercase;
  
}
  .logoStyle{
     width: 100%;
     height: 100%;
    }

  @media (max-width:900px){
    .textSize{
     font-size: 2em;
    }
     .logoStyle{
     width: 50%;
     height: 50%;
    }
    .hideShow{
        display: none;
    }
}

   
/* .loopInfinite{
   animation: animate 6s  infinite;
} */
.smoke-h1 span{
    display: inline-block;
    animation: animate 1s linear forwards ;
 /* animation-timing-function: steps(5, end); */
    margin: 7px;
}
@keyframes animate{
    0%{
        opacity: 0;
        transform: rotateY(90deg);
        filter: blur(10px)
    }
    100%{
        opacity: 1;
        transform: rotateY(0deg);
        filter:blur(0)
    }
}
.smoke-h1 span:nth-child(1){
    color:#186aa0;
    opacity: 0;
    animation-delay: 2s ;
 animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    /* animation-iteration-count: 10; */
}
.smoke-h1 span:nth-child(2){
    opacity: 0;
    animation-delay: 2.5s;
     
}
.smoke-h1 span:nth-child(3){
    color:#186aa0;
    opacity: 0;
    animation-delay: 2.75s;
}
.smoke-h1 span:nth-child(4){
    opacity: 0;
    animation-delay: 3s;
}
.smoke-h1 span:nth-child(5){
    color:#186aa0;
    opacity: 0;
    animation-delay: 3.5s;
}
.smoke-h1 span:nth-child(6){
    opacity: 0;
    animation-delay: 3.75s;
}
.smoke-h1 span:nth-child(7){
    color:#186aa0;
    opacity: 0;
    animation-delay: 4s;
}
.smoke-h1 span:nth-child(8){
    opacity: 0;
    animation-delay: 4.5s;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
}


.smoke-h1 span:nth-child(9){
    color:#186aa0;
    opacity: 0;
    animation-delay: 2s;
}
.smoke-h1 span:nth-child(10){
    opacity: 0;
    animation-delay: 2.5s;
}
.smoke-h1 span:nth-child(11){
    opacity: 0;
    animation-delay: 2.75s;
}
.smoke-h1 span:nth-child(12){
    opacity: 0;
    animation-delay: 3s;
}
.smoke-h1 span:nth-child(13){
    color:#186aa0;
    opacity: 0;
    animation-delay: 3.5s;
}
.smoke-h1 span:nth-child(14){
    opacity: 0;
    animation-delay: 3.75s;
}
.smoke-h1 span:nth-child(15){
    opacity: 0;
    animation-delay: 4s;
}
.smoke-h1 span:nth-child(16){
    opacity: 0;
    animation-delay: 4.5s;
}