.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
    transform: translateY(-100%);
}

.headroom--not-top {
background: linear-gradient(150deg, shapes-dark-color("step-1-gradient-bg") 15%, shapes-dark-color("step-2-gradient-bg") 70%, shapes-dark-color("step-3-gradient-bg") 94%);
}
